import '../sass/index.scss';

import $ from 'jquery';

$(() => {
  console.log('hello world!');
});

/* ハンバーガーメニュー */

$(function() {
  $('.js-spbtn').on("click", function(){
    $(this).toggleClass('open');
    $('.js-nav').toggleClass('open');
  });
});

// メニューをクリックされたら、非表示にする
$(function() {
  $('.l-nav_link').on("click", function(){
    $('.js-spbtn').removeClass('open');
    $('.js-nav').removeClass('open');
  });
});


$(function(){
  $(window).on('load',function(){
    $(".js-anime-line").delay(2000);
  });
});